const fontOptions = [
  'Inter',
  'Libre Franklin',
  'Lato',
  'Open Sans',
  'Source Sans Pro',
  'Poppins',
  'Roboto',
];

export default fontOptions;

const templateOptions = [
  {
    id: 'onyx',
    name: 'Onyx',
  },
];

// const templateOptions = [
//   {
//     id: 'onyx',
//     name: 'Onyx',
//   },
//   {
//     id: 'pikachu',
//     name: 'Pikachu',
//   },
//   {
//     id: 'gengar',
//     name: 'Gengar',
//   },
//   {
//     id: 'castform',
//     name: 'Castform',
//   },
//   {
//     id: 'glalie',
//     name: 'Glalie',
//   },
//   {
//     id: 'celebi',
//     name: 'Celebi',
//   },
// ];

export default templateOptions;

import { BsTools } from 'react-icons/bs';
import { FaAward, FaProjectDiagram } from 'react-icons/fa';
import { IoMdBriefcase, IoMdDocument } from 'react-icons/io';
import { MdPerson, MdSchool } from 'react-icons/md';
import ModalEvents from '../constants/ModalEvents';

export default [
  {
    id: 'profile',
    icon: MdPerson,
    fixed: true,
  },
  {
    id: 'objective',
    icon: IoMdDocument,
  },
  {
    id: 'work',
    icon: IoMdBriefcase,
    event: ModalEvents.WORK_MODAL,
  },
  {
    id: 'education',
    icon: MdSchool,
    event: ModalEvents.EDUCATION_MODAL,
  },
  {
    id: 'informalEducation',
    icon: FaAward,
    event: ModalEvents.INFORMAL_EDUCATION_MODAL,
  },
  {
    id: 'projects',
    icon: FaProjectDiagram,
    event: ModalEvents.PROJECT_MODAL,
  },
  {
    id: 'skills',
    icon: BsTools,
    event: ModalEvents.SKILL_MODAL,
  },
  {
    id: 'awards',
    icon: BsTools,
    event: ModalEvents.AWARD_MODAL,
  },
  {
    id: 'certifications',
    icon: BsTools,
    event: ModalEvents.CERTIFICATION_MODAL,
  },
];

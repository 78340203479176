import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from '../../../shared/Heading';
import Input from '../../../shared/Input';

const Profile = ({ id }) => {
  const { t } = useTranslation();

  return (
    <section>
      <Heading id={id} />

      <div className="grid grid-cols-2 gap-6">
        <Input
          name="firstName"
          label={t('builder.profile.firstName')}
          path="profile.firstName"
        />
        <Input
          name="lastName"
          label={t('builder.profile.lastName')}
          path="profile.lastName"
        />
      </div>

      <hr />

      <div className="grid grid-cols-2 gap-6">
        <Input
          name="city"
          label={t('builder.profile.address.city')}
          path="profile.address.city"
        />
        <Input
          name="pincode"
          label={t('builder.profile.address.pincode')}
          path="profile.address.pincode"
        />
      </div>

      <hr />

      <Input
        name="phone"
        label={t('shared.forms.phone')}
        path="profile.phone"
      />
      <Input
        name="email"
        label={t('shared.forms.email')}
        path="profile.email"
      />
      <Input name="linkedin" label="LinkedIn" path="profile.linkedin" />
    </section>
  );
};

export default memo(Profile);
